import React from 'react';
import SEO from 'components/seo';
import Layout from 'components/Layout';
import Link from 'components/Link';
import Button from 'components/Button';
import { Grid, Typography } from '@smooth-ui/core-sc';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Grid py={8}>
      <Typography variant="h1" mb={2}>
        Oeps, verdwaald! Deze link werkt niet meer, maar geen nood…
      </Typography>
      <Button as={Link} to="/">
        Home
      </Button>
    </Grid>
  </Layout>
);

export default NotFoundPage;
